import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Terms from './style'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
export default function TermsConditions(){
return(
<Terms>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
        <Terms.Box>
          <Terms.Title as="h3">Terms of use</Terms.Title>
        </Terms.Box>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="col-xxl-8 col-xl-9 col-lg-10">
        <Terms.Content>
          
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Welcome to We-Connect!</Terms.TitleSmall>
            <Terms.Text>These Terms of Service (the "Terms") govern your access to and use of the We-Connect Services and the Software (as defined below), all together, the "Services"). Please read them carefully before using the Services.</Terms.Text>
            <Terms.Text>By using the Services, you are agreeing to the terms and conditions of the Agreement. If you are using the Services on behalf of an organization, you are agreeing to the Agreement on behalf of that organization and representing that you have the authority to bind that organization to the Agreement. In that case, "you" and "your" will refer to that organization. We-Connect and you are each a “Party” to the Agreement.</Terms.Text>
            <Terms.Text>You may use the Services only in accordance with the Agreement. You may use the Services only if you have the legal power and capacity to form a contract with We-Connect.</Terms.Text>
            <Terms.Text>We-Connect reserves the right to modify the Terms at any time, posting any updates on the Platform. Your access or use of the Software after the notice is posted indicates acceptance of those changes.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">1. USING We-Connect - YOUR COMMITMENTS</Terms.TitleSmall>
            <Terms.Text>To ensure good functioning, and in exchange for such services, we require that you commit to the following, notably when accessing and using our Platform and Services:</Terms.Text>
            <Terms.Text>• You must be a human. Use of the Platform by “bots” is not permitted.</Terms.Text>
            <Terms.Text>• You agree to these Terms and the Privacy Policy as an individual, or for your company, if You are using our Platform as a single entity.</Terms.Text>
            <Terms.Text>• If You have communicated your login to your account to a third party, or your Platform access code, voluntarily or involuntarily, You will be entirely responsible for any decisions that the third party may make on your behalf from Platform. We-Connect is not responsible for the actions of third parties, which will be deemed to be your actions. Your use of Platform is at your sole risk. Platform is provided on an “as is” and “as available” basis, and we will not be liable for failure to deliver or transmit any information from your account, which is your sole responsibility. We-Connect interacts with different social networks to access your account. If these do not work or have blocked their access via We-Connect cannot be held responsible.</Terms.Text>
            <Terms.Text>• Any decision You make via Platform is permanent. This means that once You have chosen to delete Your Content and/or to transfer it to a Storage Service, we cannot retrieve your content or transfer it back to your account it came from. Therefore, we invite You to carefully evaluate all the requests You make with Platform. We decline any responsibility in the event You regret any such request.</Terms.Text>
            <Terms.Text>• If You send us any question, comment, suggestion, enhancement, idea, and other information regarding Platform, we will be entitled to use and disclose them without any restriction, whether commercial or not, free-of-charge.</Terms.Text>
            <Terms.Text>• You must use Platform fairly, in accordance with this Agreement, applicable laws and regulations, including intellectual and industrial property laws. Platform may contain elements, materials and content copyrighted and/or protected by patent and/or intellectual property laws.</Terms.Text>

            <Terms.Text>• Platform, trademarks, designs, models, images, texts, photos, logos, graphic charts, software and programs, databases, sounds, videos, domain names, designs or any other element composing the application, with the exception of your accounts, are the exclusive property of We-Connect and are protected by any intellectual or industrial property right recognized by the laws in force.</Terms.Text>
            <Terms.Text>• Consequently, We-Connect cannot be held liable for the sole fact of the use of our services by You.</Terms.Text>
            <Terms.Text>• You should not use Platform in violation of this Agreement, applicable laws and/or third party rights. If You do, we can suspend the use of the system and close your account. We can also communicate all necessary information to the competent services in charge of the repression of offences.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">2. ACCEPTABLE USE</Terms.TitleSmall>
            <Terms.Text>You shall not</Terms.Text>
            <Terms.Text>(a) provide System passwords or other log-in information to any unauthorized third-party;</Terms.Text>
            <Terms.Text>(b) share nonpublic System features or content with any third-party;</Terms.Text>
            <Terms.Text>(c) access the System in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics of the System, or to copy any ideas; or</Terms.Text>
            <Terms.Text>(d) engage in web scraping or data scraping on or related to the System, including without limitation collection of information through any software that simulates human activity or any bot or web crawler.</Terms.Text>
            <Terms.Text>If ID123 suspects that you have violated the requirements of this section, we may suspend your access to the System without advanced notice, in addition to other remedies we may have.</Terms.Text>
            <Terms.Text>ID123 is not obligated to take any action against you or any other System user or other third party for violating this Agreement, but we are free to take any such action it sees fit.</Terms.Text>
            
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">3. FREE TRIAL PERIOD</Terms.TitleSmall>
            <Terms.Text>We, at our sole discretion, permit the User to open an account on a “free trial” basis for the use of the Service at no charge and for a limited period of time. The Customer acknowledges and agrees that the Service will cease from being provided after the free trial period. Thereafter, the Customer will need to purchase the subscription service plan.
            During this free trial period We-connect reserves the right to terminate its Service any time for any reason.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">4. LINKS TO OTHER WEBSITES</Terms.TitleSmall>
            <Terms.Text>Our Service may contain links to third party websites or services that are not owned or controlled by We-Connect.</Terms.Text>
            <Terms.Text>We-Connect has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that We-Connect shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such web sites or services.</Terms.Text>
            <Terms.Text>We strongly advise you to read the terms and conditions and privacy policies of any third party websites or services that you visit.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">5. SUBSCRIPTIONS</Terms.TitleSmall>
            <Terms.Text>Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis (yearly), depending on the type of subscription plan you select when purchasing a Subscription.</Terms.Text>
            <Terms.Text>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or We-Connect cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting We-Connect customer support team.</Terms.Text>
            <Terms.Text>A valid payment method, including a credit card, is required to process the payment for your Subscription which is processed via Stripe, a third party payment provider. You shall provide We-Connect with accurate and complete billing information including full name, address, state, zip code, and valid payment method information. By submitting such payment information, you automatically authorize We-Connect to charge all Subscription fees incurred through your account to any such payment instruments.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">6. REFUNDS</Terms.TitleSmall>
            <Terms.Text>Except when required by law, paid Subscription fees are non-refundable.</Terms.Text>
            <Terms.TitleSmall as="h3">7. CODE OF CONDUCT</Terms.TitleSmall>
            <Terms.Text>The User acknowledges and agrees that:</Terms.Text>
            <Terms.Text>1. the User is responsible for his or her published content’s compliance with the requirements of the current law of the (1) USA, including without limitation CAN-SPAM Act, (2) the hosting country of the site, and (3) the country(-ies) where the potential end users or recipients of the emails may reside. The User is also responsible for third parties if the User’s publication of any content violates the right and legitimate interests of third parties, including the personal copyright intangible and tangible rights and other intellectual rights of third parties, and/or encroaches on their property;</Terms.Text>
            <Terms.Text>2. the We-Connect is not required to review any type of content that is published and/or distributed through the Service;</Terms.Text>
            <Terms.Text>3. We-Connect has the right (but not the obligation) to deny, at its discretion, the User’s ability to post and/or distribute the content made available through the Service;</Terms.Text>
            <Terms.Text>4. he or she must solely evaluate all risks associated with the use of content, including an evaluation of the reliability, completeness, or usefulness of this content;</Terms.Text>
            <Terms.Text>5. the technology responsible for the Service’s operation may require copying (reproducing) User content, and/or require the We-Connect to reformulate such content to meet the technical requirements of an Internet resource and/or its functions; such use of content shall not be considered a violation of intellectual property rights;</Terms.Text>
            <Terms.Text>6. he or she is solely liable to third parties for his or her actions related to the use of the Service, including when such actions result in a violation of the rights and legitimate interests of third parties, as well as for compliance with current law when using the Service;</Terms.Text>
            <Terms.Text>7. it is prohibited to publish and/or distribute content that:</Terms.Text>
            <Terms.Text>a) is illegal, malicious, and/or slanderous;</Terms.Text>
            <Terms.Text>b) constitute unsolicited commercial email or unsolicited bulk or spam email;</Terms.Text>
            <Terms.Text>c) offends morality or exploits influence resulting from fear or superstition;</Terms.Text>
            <Terms.Text>d) exploits the trust of a party and his or her lack of experience or knowledge;</Terms.Text>
            <Terms.Text>e) demonstrates (or promotes) violence and cruelty, criminal and/or terrorist actions,</Terms.Text>
            <Terms.Text>f) disfigured bodies, injuries, death, diseases, inaesthetic and disgusting images;</Terms.Text>
            <Terms.Text>g) violates intellectual property rights;</Terms.Text>
            <Terms.Text>h) promotes hatred and/or discrimination against people by race, ethnic, sexual orientation, religious, social views and beliefs, eye color, age, property status, national or social origin, etc.;</Terms.Text>
            <Terms.Text>i) contains insults to any person or organization, denigrates, denounces or derides another person, his or her activity, name (company), goods or services, trademarks;</Terms.Text>
            <Terms.Text>j) uses the name, surname, company name, or another identification mark (including trademark) of another entrepreneur without the consent of such entrepreneur;</Terms.Text>
            <Terms.Text>k) contains elements (or promotes) pornography, child erotica, adult content in content for family viewing, or advertises marriage services;</Terms.Text>
            <Terms.Text>l) may lead to the exploitation or poses a threat to minors;</Terms.Text>
            <Terms.Text>m) advertises high-risk investments or impossible ‘get-rich-quick’ schemes;</Terms.Text>
            <Terms.Text>n) promotes illegal activities;</Terms.Text>
            <Terms.Text>o) explains the procedure for manufacturing, consuming, or otherwise using narcotic substances or their analogs, as well as explosives or other weapons;</Terms.Text>
            <Terms.Text>p) advertises prescription or digital narcotic drugs, online pharmacies;</Terms.Text>
            <Terms.Text>q) contains malware, phishing, or spam;</Terms.Text>
            <Terms.Text>r) misleads or contains inaccurate or deceptive information, false promises or fraud;</Terms.Text>
            <Terms.Text>s) offers products or services that predict the future;</Terms.Text>
            <Terms.Text>t) contains opinions and positions (endorsements) of celebrities without their consent, and/or depicts, uses, or otherwise mentions any individual (as a private person or as an official) or his or her property without the consent of such individual;</Terms.Text>
            <Terms.Text>u) contains obscenities (including “F-words”) and/or hate speech;</Terms.Text>
            <Terms.Text>v) advertises counterfeit goods or hazardous goods and services (which may be harmful to health and/or cause content or other damage);</Terms.Text>
            <Terms.Text>w) creates a fake sense of urgency in the text or image that calls the visitor to action;</Terms.Text>
            <Terms.Text>x) contains fake consumer reviews and/or their imitation</Terms.Text>
            <Terms.Text>y) misleads the end users (for example, who claim or promise that the visitor to the website has become or will become the winner of a campaign, drawing, etc.), imitates the interface of any applications, system notifications, software, etc. (including buttons, such as, “close”, “download”), which may mislead the end user;</Terms.Text>
            <Terms.Text>z) advertises sites that force a visitor to fraudulently pay for any services and/or Internet resources that pursue the goal of receiving prepaid SMS messages from visitors (MT subscriptions), as well as websites that contain malicious software or whose purpose is to deceive visitors (for example, offer file downloads, browser updates, pseudo-antivirus programs, “prizes” from known Internet resources and other fake websites).</Terms.Text>
            <Terms.Text>8. Company may reconsider abovementioned prohibitions on a case-by-case basis subject to User’s request and only if there are no express prohibitions by a competent government agency and all legally established conditions are met;</Terms.Text>
            <Terms.Text>9. We-Connect reserves the right to deny, at its discretion, the User to publish and/or distribute the content and/or delete such content available through the Service without providing any justification.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">8. YOUR DATA</Terms.TitleSmall>
            <Terms.Text>You may link no more email accounts than the total number of your authorized users, defined herein as an employee, contractor, or agent of yours who is authorized to use the We-Connect Services and who has access to the We-Connect Services via a unique username and password under your account with We-Connect (“Your Authorized Users”). With your permission (which you are granting by using the Services).</Terms.Text>
            <Terms.Text>"Your Data" means any data and content stored or transmitted via the Services by or on behalf of you or your end-users (which may include data you elect to import from third party services you use). This includes messages you send, files you upload, comments on files, and anything else you enter or upload into We-Connect.</Terms.Text>
            <Terms.Text>You retain full ownership of all of the address books, emails, calendars, social media contacts, call history and files you upload or otherwise provide to We-Connect (“Your Data”). We do not claim any ownership over any of Your Data.</Terms.Text>
            <Terms.Text>You retain full ownership of all of the address books, emails, calendars, social media contacts, call history and files you upload or otherwise provide to We-Connect (“Your Data”). We do not claim any ownership over any of Your Data.</Terms.Text>
            <Terms.Text>You are solely responsible for your conduct, the content of Your Data and your communications with others while using the Services. For example, it's your responsibility to ensure that you have all rights and permissions needed to comply with the Agreement and to avoid infringement or violation of any rights of others.</Terms.Text>
            <Terms.Text>You acknowledge that We-Connect has no obligation to monitor any information on the Services and that we are not responsible for the accuracy, completeness, appropriateness, safety or legality of Your Data or any other information or content you may be able to access using the Services.</Terms.Text>
            <Terms.Text>Nothing in the Agreement will restrict We-Connect from collecting, using and analyzing general information and data from its customers (including you) in an aggregated manner for purposes of improving and enhancing the quality and nature of Services, or to market or publish general information and statistics, provided that We-Connect does not specifically identify You or disclose publicly any personally identifiable information in the course of collecting, using, analyzing, marketing or publishing that information or data. Additional information about what we do with Your Data and how we collect and use other information relating to You and Your use of the Services is explained in our <AnchorLink to="/privacy">Privacy Policy</AnchorLink>.</Terms.Text>
            <Terms.Text>We-Connect will implement appropriate technical and organizational measures designed to protect Your Data in accordance with “Applicable Data Protection Laws”, which mean the relevant data protection and data privacy laws, rules and regulations to which the Customer Personal Data are subject. “Applicable Data Protections Law(s)” shall include, but not be limited to, the California Consumer Privacy Act of 2018 (“CCPA”), and the General Data Protection Regulation (EU 2016/679) (the “GDPR”). To the extent that We-Connect may process Your Data on your behalf that includes personal data (as defined by Applicable Data Protection Laws) pursuant to the Agreement, each Party will comply with the obligations set forth in this Data Processing Agreement, which is incorporated by reference into these Terms.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">9. SHARING YOUR DATA, THIRD PARTY PRODUCTS AND YOUR PRIVACY</Terms.TitleSmall>
            <Terms.Text>Subject to the limited exceptions specified in our <AnchorLink to="/privacy">Privacy Policy</AnchorLink>, until you choose otherwise, all of Your Data remains visible only to you. However, the Services do allow you to share Your Data with others. If you choose to share Your Data, we cannot be responsible for what those other users do with Your Data, so please carefully consider what you share and with whom you share it.</Terms.Text>
            <Terms.Text>A Third Party Product is any third party product, application, service, software, network, system, directory, website, database and/or information obtained separately by you which links to the Services, or which you may connect to or enable in conjunction with the Services, including, without limitation, Third Party Products which may be integrated directly into the Services by you or at your direction. If you or your Authorized Users choose to share Your Data in any manner, including through a Third Party Product or integration you are solely responsible for what that third party may do with Your Data, and your relationship with that third party. Likewise, you are solely responsible for any of the effects a Third Party Product may have on Your Data, including deleting or corrupting Your Data. You acknowledge that We-Connect is not responsible for the disclosure of Your Data by you or your agents (including your Authorized Users) to any third parties or the effects of any Third Party Product on Your Data.</Terms.Text>
            <Terms.Text>What we do with Your Data, and how we collect and use other information relating to you generally is explained in our <AnchorLink to="/privacy">Privacy Policy</AnchorLink>.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">10. YOUR RESPONSIBILITIES</Terms.TitleSmall>
            <Terms.Text>Information and content accessible through the Services may be protected by intellectual property rights of others. Please do not copy, upload, download, or share any information or content unless you have the right to do so. You, not We-Connect, will be fully responsible and liable for what you copy, share, upload, download or otherwise use while using the Services. You must not upload spyware or any other malicious software to the Services, or use the Services to spam others.</Terms.Text>
            <Terms.Text>You acknowledge that, as between you and We-Connect, We-Connect owns and retains all right, title and interest in the Intellectual Property Rights in the Services. You own and retain all right, title, and interest in the Intellectual Property Rights in Your Data. “Intellectual Property Rights” means: (i) copyrights and other rights associated with works of authorship; (ii) trademark and trade name rights and similar rights; (iii) trade secret rights; (iv) patents, designs, algorithms, utility models, and other industrial property rights, and all improvements thereto; and (v) all registrations, applications, renewals, extensions, continuations, divisions, or reissues now or in the future.</Terms.Text>
            <Terms.Text>You, and not We-Connect, are responsible for maintaining and protecting all of Your Data. We-Connect will not be liable for any loss or corruption of Your Data, or for any costs or expenses associated with backing up or restoring any of Your Data.</Terms.Text>
            <Terms.Text>If any information related to your account changes, you must notify us promptly and keep your information current. The Services are not intended for use by you if you are under 18 years of age. By agreeing to these Terms, you are representing to us that you are over 18.</Terms.Text>
            <Terms.Text>The Services are subject to the trade laws and regulations of the United States and other countries, including the Export Administration Regulations (EAR, 15 CFR Part 730 et seq.) and the sanctions programs administered by the Office of Foreign Assets Control (OFAC, 31 CFR Part 500).</Terms.Text>
            <Terms.Text>You will not import, export, re-export, transfer or otherwise use the Services in violation of these laws and regulations, including by engaging in any unauthorized dealing involving (i) a U.S. embargoed country (currently Cuba, Iran, North Korea, Sudan and Syria), (ii) a party included on any restricted person list, such as the OFAC Specially Designated Nationals List, or the Commerce Department’s Denied Persons List or Entity List, or (iii) the design, development, manufacture, or production of nuclear, missile, or chemical or biological weapons. By using the Services, you represent and warrant that you are not located in any such country or on any such list.</Terms.Text>
            <Terms.Text>You will not engage in activity that would cause We-Connect to be in violation of these laws and regulations, and will indemnify We-Connect for any fines, penalties or other liabilities incurred by We-Connect for your failure to comply with this provision.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">11. TERMINATION/SUSPENSION OF SERVICE</Terms.TitleSmall>
            <Terms.Text>If We-Connect determines in its sole discretion that You or any of Your Authorized Users have violated any element of these Terms of Service, You or Your Authorized Users’ access may be immediately suspended or terminated, up to and including the termination of the Agreement by We-Connect, with or without notice. We-Connect shall not be liable for any damages of any nature suffered by any user, or any third party, resulting in whole or in part from We-Connect’s exercise of its rights under these Terms of Service.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">12. ACCOUNT SECURITY</Terms.TitleSmall>
            <Terms.Text>You are responsible for keeping the password that you use to access the Services secure and you agree not to provide your password to any third party. You are responsible for all activity using your account, whether or not authorized by you. Accordingly, you should notify We-Connect immediately of any unauthorized use of your account. You acknowledge that if you wish to protect Your Data when you are transmitting it to We-Connect, other than via the We-Connect Services, it is your responsibility to use a secure encrypted connection to do so.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">13. TERM AND TERMINATION; SUSPENSION</Terms.TitleSmall>
            <Terms.Text>The Agreement shall begin on the effective date as specified in your Order (“Order Effective Date”) and shall continue as specified in your Order (the “Initial Term”), automatically renewing thereafter for the same period of time (“Renewal Term” and together with the Initial Term, the “Term”) unless earlier terminated. We reserve the right to suspend or terminate the Services in whole or part at any time, with or without cause, and with or without notice, without incurring liability of any kind. For example, we may suspend or terminate your use if you are not complying with the Agreement, or if you use the Services in any way that may cause us legal liability or disrupt others' use of the Services or damage to our business or reputation, or for any other reason. If we suspend or terminate your use, we will try to let you know in advance and help you retrieve data, though there may be some cases (for example, repeatedly or flagrantly violating the Agreement, a court order, or danger to other users) where we may suspend or terminate immediately. You acknowledge that if your access to the Services is suspended or terminated, you may no longer have access to Your Data that is stored with the Service.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">14. WARRANTIES</Terms.TitleSmall>
            <Terms.Text>We-Connect warrants that: (i) it will perform the Services under the good commercial practices; (ii) it will provide the Services in a professional manner, consistent with recognized industry security standards; (iii) it will comply with all applicable law, and be duly licensed and otherwise authorized to provide the Services; and (iv) it has the authority and right to enter into the Agreement and to observe and perform its respective obligations contained in the Agreement.</Terms.Text>
          
          <Terms.Text>Customer warrants that it has the authority and right to enter into the Agreement and to observe and perform its respective obligations contained in the Agreement.</Terms.Text>
          <Terms.Text>WITH THE EXCEPTION OF THOSE EXPRESS WARRANTIES MADE IN THIS SECTION, TO THE MAXIMUM EXTENT PERMITTED BY LAW, We-Connect DISCLAIMS ALL WARRANTIES WHETHER EXPRESS, IMPLIED OR STATUTORY.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">15. INDEMNIFICATION</Terms.TitleSmall>
            <Terms.Text>Your indemnity to us: You will defend and hold us and our affiliates, employees, officers, directors, agents, successors and assigns, harmless at your own expense, against any and all third party liability (including damages, recoveries, deficiencies, interest, penalties and legal fees), directly or indirectly arising from or in connection with: (i) Your Data; (ii) your violation of any third party rights (including third party intellectual property rights or privacy rights); and (iii) your use of the Services in any way contrary to the Agreement.</Terms.Text>
            <Terms.Text>Our indemnity to you: We will defend and hold you and your employees, officers, directors, agents, successors and assigns, harmless at our own expense, against any and all third party liability (including damages, recoveries, deficiencies, interest, penalties and legal fees), directly or indirectly arising from or in connection with any third party claims that the Services infringe or misappropriate the intellectual property rights of a third party.</Terms.Text>
            <Terms.Text>The Party seeking indemnification under the Agreement will: (i) give the indemnifying Party prompt written notice of the claim, (ii) tender to the indemnifying Party control of the defense and settlement of the claim, and (iii) cooperate with the indemnifying Party in defending or settling the claim. The indemnified Party will have the right to participate at its own expense in any indemnification action or related settlement negotiations using counsel of its own choice. Neither Party may consent to the entry of any judgment or enter into any settlement that adversely affects the rights or interests of the other Party without that Party’s prior written consent, which may not be unreasonably withheld.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">16. LIMITATION OF LIABILITY</Terms.TitleSmall>
            <Terms.Text>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL We-Connect, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL (INCLUDING LOSS OF USE, DATA, BUSINESS, OR PROFITS) DAMAGES, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT We-Connect HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE. THE AGGREGATE LIABILITY OF We-Connect TO YOU, ANY AFFILIATE OR ANY THIRD PARTY FOR ALL CLAIMS RELATING TO THE SERVICES OR CONNECTED WITH THE AGREEMENT, REGARDLESS OF THE DAMAGES THEORY, WILL NOT EXCEED THE FEES PAID OR OWING TO We-Connect UNDER THE AGREEMENT IN THE TWELVE (12) MONTHS PRECEDING THE DATE THE CLAIM AROSE. THE LIMITATION OF LIABILITY PROVIDED IN THIS PARAGRAPH WILL APPLY IN THE AGGREGATE TO YOU AND YOUR AFFILIATES AND SHALL NOT BE CUMULATIVE. THE PARTIES ACKNOWLEDGE AND AGREE THAT THE PURPOSE OF THIS PARAGRAPH IS TO PROVIDE FOR THE ALLOCATION OF RISK AND LIMIT POTENTIAL LIABILITY GIVEN THE FEES PAID, WHICH WOULD HAVE BEEN SUBSTANTIALLY HIGHER IF WE WERE TO ASSUME ANY FURTHER LIABILITY THAN PROVIDED FOR HEREIN.</Terms.Text>
            <Terms.Text>Some states do not allow the types of limitations in this paragraph, so they may not apply to you. IN THOSE JURISDICTIONS, We-Connect’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</Terms.Text>
            <Terms.Text>In addition, We-Connect and its authorized personnel may require access to Your Data or account for the purposes of assisting you with any step of your account setup or any other troubleshooting, including attaining email connectivity. Neither We-Connect nor any of its employees will be liable in cases where this access exposes us to your private data, including prospect info and email metadata and content. By using We-Connect, you agree to permit We-Connect representatives these permissions only when circumstances necessitate it. We reserve the right to assist in these ways when needed and will keep information confidential as per our <AnchorLink to="/privacy">Privacy Policy</AnchorLink>.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">17. MODIFICATIONS</Terms.TitleSmall>
            <Terms.Text>We may revise these Terms from time to time and the most current version will always be posted on our website. We will notify you of any change (for example via email to the email address associated with your account or by a notification when you sign in or by some other method). By continuing to access or use the Services after revisions become effective, you are agreeing to be bound by the revised Terms. If you do not agree to the new Terms, simply don't use the Services after the change is effective, in which case the change will not apply to you.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">18. MISCELLANEOUS</Terms.TitleSmall>
            <Terms.Text>- Governing Law: This Agreement will be governed by the laws of Delaware, without giving effect to the principles of conflict of law.</Terms.Text>
            <Terms.Text>Some states do not allow the types of limitations in this paragraph, so they may not apply to you. IN THOSE JURISDICTIONS, We-Connect’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</Terms.Text>
            <Terms.Text>- Force Majeure: Neither party to these terms will be deemed responsible or liable for its failure to perform or delay in performance under these Terms where such delay or failure is beyond its control, such as were caused by internet or telecommunications failures, shortages of or inability to obtain labor, energy, or supplies, war, terrorism, riot, acts of God or governmental action, natural disasters, COVID-19 and variants.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">CONTACT INFORMATION</Terms.TitleSmall>
            <Terms.Text>Questions about this agreement should be sent to us at <a href="mailto: privacy@We-Connect.io">privacy@We-Connect.io</a></Terms.Text>
          </Terms.ContentBox>
        </Terms.Content>
      </Col>
    </Row>
  </Container>
</Terms>

)
}